import * as React from "react";
import Layout from "../../layouts";
import QuoteSection from "@partials/quote-section/quote-section";
import "./_about-styles.scss";
import ImageBlock from "@partials/image-block/image-block";
import SweetDream from "@images/sweet-dream.png";
import SweetDreamX2 from "@images/sweet-dream-x2.png";
import { quotes } from "../../../pageData";


export default function About() {
    return (
        <Layout pageTitle="About">
            <section className="about-hero-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center c-mb-7">
                        <h1 className="c-mb-only-2">About Artists’ Postcards</h1>
                        <div className="col-lg-6 lh-1-6">
                            <p>
                            From 1977 to 1980, Artists’ Postcards, a non-profit, privately funded arts organization launched by Joan K. Davidson, commissioned contemporary visual artists, writers, photographers, architects, and designers to create original works of art in 4 X 6 inch format.
                            </p>
                            <p>
                                The eighty-nine artists from Series I and Series II ranged from the very well known, such as David Hockney, Wolf Kahn, Robert Motherwell, Duane Michals, Lee Krasner, Betye Saar, Wayne Thiebaud, John Ashbery, Saul Steinberg, Alan Gussow, Robert Mapplethorp, and Massimo Vignelli, to newcomers Michael Langenstein, Carol Anthony, Frances, Butler, Russell Drisch, and Christopher Hewat and many others.
                            </p>
                            <p>
                                The original 4 X 6 inch works were exhibited initially at The Drawing Center (Series I) and the Cooper - Hewitt Museum (Series II), and then traveled to museums across the country under the auspices of the Smithsonian Institution Traveling Exhibition Service.
                            </p>
                            <p>
                            Among the many notable images created for the venture, Michael Langenstein’s iconic flicker-photo card of the twin towers of the World Trade Center juxtaposed against both the ancient Egyptian desert and the landscape of the moon today hangs in the National September 11 Memorial and Museum.
                            </p>
                        </div>
                        <div className="col-lg-6 lh-1-6">
                            <p>
                                The mission was to enable artists to create original postcard size works that when precisely reproduced could be widely distributed for the cost of a first-class stamp, stimulating written communication in a new creative format.
                            </p>
                            <ul className="c-pl-only-4">
                                <li className="mb-0">Joan K. Davidson</li>
                                <li className="mb-0">M.J.Gladstone</li>
                                <li className="mb-0">Niland Mortimer</li>
                                <li className="mb-0">Patricia Falk</li>
                            </ul>
                            <ImageBlock
                                image={SweetDream}
                                imageX2={SweetDreamX2}
                                alt="postcard that shows nature and cloud"
                                classNames="mb-0"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 mx-auto">
                            <p className="fz-24 lh-1-5">
                                Reviewing the opening Series I exhibition in the November 25th, 1977
                                edition of The New York Times,Hilton Kramer wrote,
                            </p>
                            <p className="fst-italic mb-0">
                                “The epoch of the picture postcard is, apparently, upon us. Like some waif suddenly rescued from the obscurity in a 19th century melodrama, the postcard has been elevated to a new status. It has been claimed as heir to the art of the present age.And, so — presto! — we have “Artists’ Postcards,” an exhibition that enlists the talents of such luminaries of the current scene as John Cage and Robert Motherwell, Lee Krasner and Donald Barthelme, Saul Steinberg and John Ashbery... a remarkable demonstration of diverse talents working to order on an unusual assignment.”
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.about} />
        </Layout>
    );
}
